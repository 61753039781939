import React from "react"
import Seo from "src/utils/seo"
import Layout from "components/Layout/Layout"
import SettingsManager from "components/SettingsManager/SettingsManager"
import Asin from "components/Asin/Asin"

export default function Page() {
    return(
        <React.Fragment>
            <Seo title="ASINs" />
            <Layout showActions={true} showBulk={false}>
                <Asin page="asin" />
            </Layout>
            <SettingsManager/>
        </React.Fragment>
    )
}