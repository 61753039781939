import React from "react"
import {
    formatNumberToCurrency,
    formatNumberToPercent,
    formatNumber,
} from "src/utils/utils"

export const COLUMNS = [
    {
        keyTop:"campaignName",
        labelTop:"Campaign Name",
        keyBottom:"campaignState",
        labelBottom:"Campaign State",
        sortTop:true,
        template:(item) => (
            <>
                <span>{item["campaignName"]}</span>
                <br/>
                <span>{item["campaignState"]}</span>
            </>
        ),
    },
    {
        keyTop:"adGroupName",
        labelTop:"AdGroup Name",
        keyBottom:"adGroupState",
        labelBottom:"AdGroup State",
        template:(item) => (
            <>
                <span>{item["adGroupName"]}</span>
                <br/>
                <span>{item["adGroupState"]}</span>
            </>
        ),
    },
    {
        keyTop:"keywordText",
        labelTop:"Keyword Name",
        keyBottom:"keywordState",
        labelBottom:"Keyword State",
        sortTop:true,
        template:(item) => (
            <>
                <span>{item["keywordText"]}</span>
                <br/>
                <span>{item["keywordState"]}</span>
            </>
        ),
    },
    {
        keyTop:"asin",
        labelTop:"ASIN",
        keyBottom:"sku",
        labelBottom:"SKU",
        sortTop:true,
        sortBottom:true,        
        template:(item) => (
            <>
                <span>{item["asin"]}</span>
                <br/>
                <span>{item["sku"]}</span>
            </>
        ),
    },
    {
        keyTop:"oAsin",
        labelTop:"Other ASIN",
        keyBottom:"matchType",
        labelBottom:"KW Match",
        sortTop:true,
        sortBottom:true,        
        template:(item) => (
            <>
                <span>{item["oAsin"]}</span>
                <br/>
                <span>{item["matchType"]}</span>
            </>
        ),
    },
    {
        keyTop:"units1",
        labelTop:"Units1",
        keyBottom:"unitsOSKU1",
        labelBottom:"(oSKU1)",
        sortTop:true,
        sortBottom:true,        
        template:(item) => (
            <>
                <span>{item["units1"]}</span>
                <br/>
                <span>{item["unitsOSKU1"]}</span>
            </>
        ),
    },
    {
        keyTop:"units7",
        labelTop:"Units7",
        keyBottom:"unitsOSKU7",
        labelBottom:"(oSKU7)",
        sortTop:true,
        sortBottom:true,        
        template:(item) => (
            <>
                <span>{item["units7"]}</span>
                <br/>
                <span>{item["unitsOSKU7"]}</span>
            </>
        ),
    },
    {
        keyTop:"units14",
        labelTop:"Units14",
        keyBottom:"unitsOSKU14",
        labelBottom:"(oSKU14)",
        sortTop:true,
        sortBottom:true,        
        template:(item) => (
            <>
                <span>{item["units14"]}</span>
                <br/>
                <span>{item["unitsOSKU14"]}</span>
            </>
        ),
    },
    {
        keyTop:"units30",
        labelTop:"Units30",
        keyBottom:"unitsOSKU30",
        labelBottom:"(oSKU30)",
        sortTop:true,
        sortBottom:true,        
        template:(item) => (
            <>
                <span>{item["units30"]}</span>
                <br/>
                <span>{item["unitsOSKU30"]}</span>
            </>
        ),
    },
    {
        keyTop:"salesOSKU1",
        labelTop:"OtherSKU Sales1",
        // keyBottom:"maxAcos",
        // labelBottom:"Sales1",
        sortTop:true,
        // sortBottom:true,        
        template:(item) => (
            <>
                <span>{item["salesOSKU1"]}</span>
            </>
        ),
    },
    {
        keyTop:"salesOSKU7",
        labelTop:"OtherSKU Sales7",
        // keyBottom:"maxAcos",
        // labelBottom:"Sales1",
        sortTop:true,
        // sortBottom:true,        
        template:(item) => (
            <>
                <span>{item["salesOSKU7"]}</span>
            </>
        ),
    },
    {
        keyTop:"salesOSKU14",
        labelTop:"OtherSKU Sales14",
        // keyBottom:"maxAcos",
        // labelBottom:"Sales1",
        sortTop:true,
        // sortBottom:true,        
        template:(item) => (
            <>
                <span>{item["salesOSKU14"]}</span>
            </>
        ),
    },
    {
        keyTop:"salesOSKU30",
        labelTop:"OtherSKU Sales30",
        // keyBottom:"maxAcos",
        // labelBottom:"Sales1",
        sortTop:true,
        // sortBottom:true,        
        template:(item) => (
            <>
                <span>{item["salesOSKU30"]}</span>
            </>
        ),
    },
]