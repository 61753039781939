import React,{useState,useEffect,useContext} from "react"
import {v4 as uuidv4} from "uuid"

import {API_URL,API_SMART_NAVIGATION} from "src/constants/constants"
import {store} from "src/context/store"
import Table from "components/Table/Table"
import {COLUMNS} from "./Columns"

import {
    formatNumberToCurrency,
    formatNumberToPercent,
    parseNumber
} from "src/utils/utils"

import SearchFilters from "components/SearchFilters/SearchFilters"
import CustomChart from "components/CustomChart/CustomChart"

import AddNew from "components/Modals/AddNew/AddNew"
import BulkAction from "components/Modals/BulkAction/BulkAction"
import SmartNavigation from "components/Modals/SmartNavigation/SmartNavigation"
import Edit from "components/Modals/Edit/Edit"
import AdvancedSearch from "components/Modals/AdvancedSearch/AdvancedSearch"

import DATA from "src/data/Campaign.json"

const Asin = ({page}) => {
    const globalState = useContext(store)

    const [isLoading,setIsLoading] = useState(false)
    const [isParentError,setIsParentError] = useState(false)
    const [data,setData] = useState([])

    const [showChart,setShowChart] = useState(false)

    const [advancedSearchModal,setAdvancedSearchModal] = useState(false)

    const [activeItem,setActiveItem] = useState()

    const [checkboxes,setCheckBoxes] = useState({})

    const handleCheckBoxesChange = (e) => {
        let name = e.target.name
        setCheckBoxes({
            [name]:e.target.checked
        })
    }

    const showAdvancedModal = () => {
        setAdvancedSearchModal(true)
    }

    const tableDataTemplate = (item,showColumns) => {
        return(
            <tr key={uuidv4()} data-campaignid={item["campaignId"]} data-campaignname={item["name"]} >
                <td>
                    <input type="checkbox" name={item["campaignId"]} onChange={handleCheckBoxesChange}/>
                </td>
                {
                    COLUMNS.map((col,idx) => {
                        return (
                            <td key={uuidv4()} data-label={col["labelTop"]} className={(showColumns["col"+idx] ? "show":"hide")}>
                                {col["template"](item)}
                            </td>
                        )
                    })
                }
            </tr>
        )
    }

    useEffect(() => {
        const searchQuery = {
            intervalCustom:globalState?.state?.customRange || "",
            intervalMonth:globalState?.state?.monthRange || "",
            dateStart:globalState?.state?.dateRange?.[0] || "",
            dateEnd:globalState?.state?.dateRange?.[1] || "",
            stateFilter:globalState?.state?.stateFilter || "",
            searchText:globalState?.state?.searchText || "",
        }
        let searchStr = ""
        Object.keys(searchQuery).forEach(key => searchStr += `${key}=${searchQuery[key]}&` )
        searchStr = searchStr.slice(0, -1)
        const abortCont = new AbortController()

        // TABLE DATA
        fetch(`${API_URL}&page=${page}&${searchStr}`,{
            method:"GET",
            credentials:'include',
            signal:abortCont.signal
        })
        .then(res => res.json())
        .then(response => {
            if(response.status === "OK"){
                if(response.data !== null){
                    setData(response.data)
                }else{
                    setData([])
                }
            }
            setIsLoading(false)
        }).catch(err => {
            console.log(err.name)
            if(err.name !== "AbortError"){
                setIsParentError(true)
            }
            setIsLoading(false)
        })


        return () => {
            abortCont.abort()            
        }
    }, [
        globalState?.state?.customRange,
        globalState?.state?.monthRange,
        globalState?.state?.dateRange,
        globalState?.state?.stateFilter,
        globalState?.state?.searchText,
    ])

    return(
        <React.Fragment>
            <SearchFilters page={page} showAdvancedModal={showAdvancedModal} />
            <Table 
                page={page}
                // DATA={DATA} 
                DATA={data} 
                checkboxes={checkboxes}
                columns={COLUMNS} 
                setShowChart={setShowChart}
                isParentError={isParentError} 
                isParentLoading={isLoading} 
                tableDataTemplate={tableDataTemplate} 
            />    
            
            { advancedSearchModal && <AdvancedSearch setAdvancedSearchModal={setAdvancedSearchModal} />}
            { globalState?.state?.showAddNewModal && <AddNew/>}    
            {/* { globalState?.state?.showBulkActionModal && <BulkAction/>}        */}
        </React.Fragment>
    )
}

export default Asin